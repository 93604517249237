import React, { useEffect, useContext, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCaretDown, faCircle, faClose } from "@fortawesome/free-solid-svg-icons";
import CircleProgressBar from "./CircleProgressBar";
import "../assets/css/snapshot.css";
import "../assets/css/styles.css";
import chartImg1 from "../assets/img/chart-1.png";
import chartImg2 from "../assets/img/chart-2.png";
import chartImg3 from "../assets/img/chart-3.png";
import chartImg4 from "../assets/img/chart-4.png";
import progressUserMale from "../assets/img/progress-icon-user-male.png";
import progressUserFemale from "../assets/img/progress-icon-user-female.png";
import progressPeak from "../assets/img/progress-icon-peak.png";
import DataContext from '../context/DataContext';

import Lottie from 'react-lottie';
import androidPreloaderData from '../assets/img/android-pre-loader.json'; 

import { useNavigate } from "react-router";

const ScoreSnapshot = ({
  unlockPlanResponse,
  denyPlanResponse,
  userGender,
  isQuitLoader,
  setIsQuitLoader,
}) => {
  const navigate = useNavigate();

  const {
    apiResponseData,
    setCurrentQuestionID,
    setProgrssBar,
    progressValue,
    setIsNavigateScore,
    isResume,
    setIsResume,
  } = useContext(DataContext);

  const chartImages = {
    1: chartImg1,
    2: chartImg2,
    3: chartImg3,
    4: chartImg4,
  };

  const scoreProgressObject = apiResponseData.scoreProgressObject?.map(
    (item, index) => {
      return {
        ...item,
        type: item.name.toLowerCase().replace(/\s/g, "_"),
        content_text: item.progress_content,
        image_url: chartImages[index + 1],
      };
    }
  );

  //End Dynamic Alteration

  //Progress Bar Object
  const knowMoreContent = [
    {
      name: "Starting",
      content:
        "You are taking the first step. Some might call it doing the bare minimum",
      color: "#6D6BDB",
      value: 20,
    },
    {
      name: "Progressing",
      content:
        "You are more than a beginner. You already have some healthy habits in place.",
      color: "#FA80B1",
      value: 20,
    },
    {
      name: "Advancing",
      content:
        "You are dedicated towards your health. You’ve made commitments that you stick you most of the time.",
      color: "#FFDB4D",
      value: 20,
    },
    {
      name: "Thriving",
      content:
        "You are energetic, constantly working towards making your health better.",
      color: "#52C1D5",
      value: 20,
    },
    {
      name: "Peak",
      content:
        "Outstanding! You're already at your peak level. Maintain this brilliance!",
      color: "#2AA248",
      value: 20,
    },
  ];

  const scoreBars = knowMoreContent.map((item, i) => {
    if (item.value > 0) {
      return (
        <div
          className="bar"
          style={{ backgroundColor: item.color, width: item.value + "%" }}
          key={i}
        ></div>
      );
    }
    return null;
  });

  const ScoreBarLegends = knowMoreContent.map((item, i) => {
    if (item.value > 0) {
      return (
        <div className="legend" key={i}>
          <span className="label">{item.name}</span>
        </div>
      );
    }
    return null;
  });

  //Static View
  const staticContentValues = [
    {
      name: "Peak",
      value: 85,
    },
    {
      name: "Empty",
      value: 15,
    },
  ];

  const staticBarValues = staticContentValues.map((item, i) => {
    if (item.value > 0) {
      let imageURL = progressPeak;

      return (
        <div className="value" style={{ width: item.value + "%" }} key={i}>
          <span>
            <img
              src={imageURL}
              alt="Questionnaire-icon"
              className={`progress-icon ${i === 0 ? "" : "visibility-hidden"}`}
            />
          </span>
        </div>
      );
    }
    return null;
  });

  //Dynamic User Score Object - API Response
  const userFlowScore = apiResponseData.userFlowScore;

  const scoreValues = userFlowScore?.map((item, i) => {
    if (item.value > 0) {
      let imageURL = progressUserMale;

      if (userGender === "female") {
        imageURL = progressUserFemale;
      }

      if (i === 1) {
        imageURL = progressPeak;
      }

      return (
        <div className="value" style={{ width: item.value + "%" }} key={i}>
          <span>
            <p
              className={`progress-icon-text ${
                i > 0 ? "visibility-hidden" : ""
              }`}
            >
              You’re Here
            </p>
            <img
              src={imageURL}
              alt="Questionnaire-icon"
              className={`progress-icon ${i === 2 ? "visibility-hidden" : ""}`}
            />
          </span>
        </div>
      );
    }
    return null;
  });

  //Slide Up Design
  const [displayModal, setDisplayModal] = useState(false);

  //Disable Background Scroll - Bootom Slide Modal
  useEffect(() => {
    if (displayModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [displayModal]);

  /*  */

  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  function ScoreCardItem({ index, object, isActive }) {
    return (
      <div className="snapshot-score-card">
        <div className="d-flex align-items-center justify-content-between w-100 my-auto">
          <div className="progress-section d-flex align-items-center">
            <CircleProgressBar
              progressValue={object?.progress_value}
              imageURL={object?.image_url}
              type={object?.type}
            />
            <div className="content-section">
              <h4 className="heading">
                {object.name}{" "}
                <FontAwesomeIcon
                  className="activity-icon"
                  icon={faCaretDown}
                  onClick={() => {
                    // toggleSlide();
                    handleItemClick(index);
                  }}
                />
              </h4>
              <p className="desc">{object.content_text}</p>
            </div>
          </div>

          <div className="status-section">
            <Badge
              bg="primary"
              className={`mx-1 badge status-${object.progress_status}`}
            >
              {object.progress_status === 1
                ? "Starting"
                : object.progress_status === 2
                ? "Progressing"
                : object.progress_status === 3
                ? "Advancing"
                : object.progress_status === 4
                ? "Thriving"
                : "Peak"}
            </Badge>
          </div>
        </div>
        <div className={`slide-container ${isActive ? "active" : "inactive"}`}>
          <div className="slide-content">
            <div className="expand-section d-flex mt-3">
              <div className="d-flex">
                <img
                  className="image"
                  src={object.image_url}
                  alt="activity-icon"
                />
                <div className="content-section">
                  <h4 className="head-text">Est. Weekly Activity</h4>
                  <p className="sub-text">You’ve been awesome</p>
                </div>
              </div>
              <div className="status">
                <h4 className={`status-text ${object.type}`}>~50 hours</h4>
              </div>
            </div>
            <div className="expand-section d-flex pb-1">
              <div className="d-flex">
                <img
                  className="image"
                  src={object.image_url}
                  alt="activity-icon"
                />
                <div className="content-section">
                  <h4 className="head-text">Cardio Strength Balance</h4>
                  <p className="sub-text">Needs a little push</p>
                </div>
              </div>
              <div className="status">
                <h4 className={`status-text ${object.type}`}>Balanced</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /* Flickering Update */

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {    


    setTimeout(() => {
      setIsQuitLoader(false); 
    }, 300);

    setTimeout(() => {
      setIsVisible(true);      
    }, 400);

  }, []);

  const isAndroid = /android/.test(navigator.userAgent.toLowerCase());
  const isIOS = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: androidPreloaderData, // or animationDataUrl for URL
  };

  function QuitPreloader() {
    return (
      isQuitLoader && (
        <>
          <div className={`loader-overlay ${!isVisible ? "fade-in" : "fade-out"}" `}></div>

          <div className={`spinner center ${!isVisible ? "fade-in" : "fade-out"}" ${!isIOS ? "hide" : ""}`}>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
          </div>
          <div className={`loader center ${isIOS ? "hide" : ""}`}>
            <Lottie options={defaultOptions}  />
          </div>
        </>
      )
    );
  }

  return (
    <>
      {isQuitLoader && <QuitPreloader />}

      <div className={`container score-snapshot ${isVisible ? "fade-in" : "fade-out"}`}>
        <header className="App-header score">
          <Button
            variant="outline-dark"
            size="sm"
            className="prev-question"
            onClick={() => {
              if (isResume) {
                denyPlanResponse();
              } else {
                navigate("/");
                setIsNavigateScore(false);
              }
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </header>

        <section className="scorecard-section">
          <h4 className="snapshot-title">Here's a snapshot of you.</h4>
          <p className="snapshot-desc">
            Dive in to discover insights and the path ahead tailored just for
            you!
          </p>

          <div className="progress-bar-section">
            {scoreProgressObject?.map((object, index) => (
              <ScoreCardItem
                key={index}
                object={object}
                isActive={index === activeIndex}
                index={index}
              />
              // <div className="snapshot-score-card" key={index}>
              //   <div className="d-flex align-items-center justify-content-between w-100 my-auto">
              //     <div className="progress-section d-flex align-items-center">
              //       <CircleProgressBar
              //         progressValue={object.progress_value}
              //         imageURL={object.image_url}
              //         type={object.type}
              //       />
              //       <div className="content-section">
              //         <h4 className="heading">
              //           {object.name}{" "}
              //           <FontAwesomeIcon
              //             className="activity-icon"
              //             icon={faCaretDown}
              //             onClick={() => {
              //               toggleSlide();
              //             }}
              //           />
              //         </h4>
              //         <p className="desc">{object.content_text}</p>
              //       </div>
              //     </div>

              //     <div className="status-section">
              //       <Badge
              //         bg="primary"
              //         className={`mx-1 badge status-${object.progress_status}`}
              //       >
              //         {object.progress_status === 1
              //           ? "Starting"
              //           : object.progress_status === 2
              //           ? "Progressing"
              //           : object.progress_status === 3
              //           ? "Advancing"
              //           : object.progress_status === 4
              //           ? "Thriving"
              //           : "Peak"}
              //       </Badge>
              //     </div>
              //   </div>
              //   <div className={`slide-container ${isSlideOpen ? "active" : "inactive"}`}>
              //     <div className="slide-content">
              //       <div className="expand-section d-flex mt-3">
              //         <div className="d-flex">
              //           <img
              //             className="image"
              //             src={object.image_url}
              //             alt="activity-icon"
              //           />
              //           <div className="content-section">
              //             <h4 className="head-text">Est. Weekly Activity</h4>
              //             <p className="sub-text">You’ve been awesome</p>
              //           </div>
              //         </div>
              //         <div className="status">
              //           <h4 className={`status-text ${object.type}`}>
              //             ~50 hours
              //           </h4>
              //         </div>
              //       </div>
              //       <div className="expand-section d-flex pb-1">
              //         <div className="d-flex">
              //           <img
              //             className="image"
              //             src={object.image_url}
              //             alt="activity-icon"
              //           />
              //           <div className="content-section">
              //             <h4 className="head-text">Cardio Strength Balance</h4>
              //             <p className="sub-text">Needs a little push</p>
              //           </div>
              //         </div>
              //         <div className="status">
              //           <h4 className={`status-text ${object.type}`}>Balanced</h4>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </div>
            ))}
          </div>

          <div className="multicolor-bar">
            <div className="scoreValues">
              {scoreValues?.length ? scoreValues : ""}
            </div>
            <div className="scoreBars">
              {scoreBars?.length ? scoreBars : ""}
            </div>
            <div className="ScoreBarLegends">
              {ScoreBarLegends?.length ? ScoreBarLegends : ""}
            </div>
          </div>

          <div className="bottom-section">
            <span
              className="know-more-link"
              onClick={() => setDisplayModal(!displayModal)}
            >
              Know more
            </span>
            <p className="know-more-desc mx-auto">
              Let's embark on this personalised journey towards your peak
              wellness!
            </p>
            <Button
              variant="outline-primary"
              size="sm"
              className="unlock-plan-button"
              onClick={() => {
                unlockPlanResponse();
              }}
            >
              Achieve my peak
              <img
                src={require("../assets/img/premium-icon.png")}
                alt="Premium-icon"
                className="premium-icon"
              />
            </Button>

            {isResume && (
              <Button
                variant="outline-primary"
                size="sm"
                className="re-assessment-button"
                onClick={() => {
                  navigate("/");
                  setIsNavigateScore(false);
                  setProgrssBar(progressValue);
                  setCurrentQuestionID(0);
                  setIsResume(false);
                }}
              >
                Retake assessment
              </Button>
            )}
          </div>
        </section>

        <div className="know-more-section">
          <div className={`Modal ${displayModal ? "Show" : ""}`}>
            <div className="container">
              <button
                className="Close"
                onClick={() => setDisplayModal(!displayModal)}
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
              <h3 className="title">Wellness Journey Stage</h3>
              <img
                src={require("../assets/img/know-more-img.png")}
                alt="know-more-img"
                className="know-more-img"
              />

              <p className="description">
                Your Wellness Journey Stage is like your own personalised
                compass. Each stage mirrors your current position on the path to
                wellness.
              </p>
              <p className="sub-description">
                Its role is to guide us in understanding how to assist you in
                reaching your peak potential. Moreover, it provides us with
                insight into the level of effort you're prepared to invest in
                specific aspects of your health.
              </p>

              <div className="multicolor-bar">
                <h5 className="range-text">Range</h5>
                <div className="staticValues">
                  {staticBarValues.length ? staticBarValues : ""}
                </div>
                <div className="scoreBars">
                  {scoreBars.length ? scoreBars : ""}
                </div>
                <div className="ScoreBarLegends">
                  {ScoreBarLegends.length ? ScoreBarLegends : ""}
                </div>
              </div>

              <div className="defintion">
                {knowMoreContent?.map((data, index) => (
                  <div key={index}>
                    <h4>
                      <FontAwesomeIcon
                        icon={faCircle}
                        style={{ color: data.color }}
                      />
                      {data.name}
                    </h4>
                    <p>{data.content}</p>
                  </div>
                ))}
              </div>

              <p className="sub-description">
                Let's embark on this journey together and ensure every step you
                take aligns perfectly with your goals and aspirations.
              </p>
            </div>
          </div>
          <div
            className={`Overlay ${displayModal ? "Show" : ""}`}
            onClick={() => setDisplayModal(!displayModal)}
          />
        </div>
      </div>
    </>
  );
};

export default ScoreSnapshot;
